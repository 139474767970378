@use '../../../../node_modules/@carbon/react/scss/breakpoint';
@use '../../../../node_modules/@carbon/react/scss/colors';

// Colors
$gray-10: colors.$gray-10;
$gray-20: colors.$gray-20;
$gray-70: colors.$gray-70;
$gray-80: colors.$gray-80;
$gray-100: colors.$gray-100;
$error-red: colors.$red-50;
$blue-10: colors.$blue-10;
$blue-40: colors.$blue-40;
$blue-40-hover: colors.$blue-40-hover;
$blue-60: colors.$blue-60;
$blue-70: colors.$blue-70;
$blue-80: colors.$blue-80;
@mixin breakpoint-between-font-size($min, $max, $fontSize) {
  @include breakpoint.breakpoint-between($min, $max) {
    font-size: $fontSize;
  }
}
@mixin breakpoint-between-width($min, $max, $width) {
  @include breakpoint.breakpoint-between($min, $max) {
    width: $width;
  }
}
@mixin breakpoint-between-many($min, $max) {
  @include breakpoint.breakpoint-between($min, $max) {
    @content;
  }
}
@mixin breakpoint-down-many($name) {
  @include breakpoint.breakpoint-down($name) {
    @content;
  }
}

.contentArea {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.textContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  padding: 0 0 1rem 1rem;
}
.date {
  padding-top: 0.5rem;
  font-size: 0.75rem;
}
.title {
  font-size: 0.875rem;
}
