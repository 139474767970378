@use '../../../../node_modules/@carbon/react/scss/breakpoint';
@use '../../../../node_modules/@carbon/react/scss/colors';

// Colors
$gray-10: colors.$gray-10;
$gray-20: colors.$gray-20;
$gray-70: colors.$gray-70;
$gray-80: colors.$gray-80;
$gray-100: colors.$gray-100;
$error-red: colors.$red-50;
$blue-10: colors.$blue-10;
$blue-40: colors.$blue-40;
$blue-40-hover: colors.$blue-40-hover;
$blue-60: colors.$blue-60;
$blue-70: colors.$blue-70;
$blue-80: colors.$blue-80;
@mixin breakpoint-between-font-size($min, $max, $fontSize) {
  @include breakpoint.breakpoint-between($min, $max) {
    font-size: $fontSize;
  }
}
@mixin breakpoint-between-width($min, $max, $width) {
  @include breakpoint.breakpoint-between($min, $max) {
    width: $width;
  }
}
@mixin breakpoint-between-many($min, $max) {
  @include breakpoint.breakpoint-between($min, $max) {
    @content;
  }
}
@mixin breakpoint-down-many($name) {
  @include breakpoint.breakpoint-down($name) {
    @content;
  }
}

@mixin full-height {
  height: 100%;
  max-height: inherit;
}

.text {
  color: white;
  font-size: 0.875rem;
  margin-bottom: 0.85rem;
  @include breakpoint-between-font-size('sm', 'md', 0.85rem);
}

.link {
  font-size: 0.875rem;
  color: $blue-40 !important;
  &:hover {
    color: $blue-40-hover !important;
  }
}

.svg {
  width: 100%;
  height: 100%;
  fill: $blue-70;
}

.iconContainer {
  height: 100%;
  margin-left: auto;
}
.iconParent {
  display: flex;
  height: 100%;
  width: 100%;
  max-height: fit-content;
}

.tile {
  display: flex;
  flex-direction: column;
  background-color: $blue-80;
  height: 100%;
  max-height: inherit;
  overflow-y: auto;
}

.dots {
  background-color: 'transparent';
  height: '100%';
  max-height: 'inherit';
  border-radius: '10px';
}

.container {
  width: 100%;
  height: 100%;
}

.header {
  font-weight: bold;
  margin-bottom: 1rem;
  color: white;
}

.carousel {
  @include full-height;
  :global .slick-list {
    @include full-height;
  }
  :global .slick-track {
    @include full-height;
  }
  :global .slick-slide > div {
    @include full-height;
  }
  :global .slick-dots {
    bottom: -4rem;
    display: inline-flex;
    padding-left: 2rem;
    li {
      margin: 0 6px;
      @include breakpoint-between-width('sm', 'md', 1rem);
      button {
        width: 1.875rem;
        @include breakpoint-between-width('sm', 'md', 1.3rem);
        height: 0.25rem;
        &:before {
          width: 1.875rem;
          @include breakpoint-between-width('sm', 'md', 1.3rem);
          height: 0.25rem;
          content: '';
          text-align: center;
          opacity: 1;
          background-color: $blue-60;
          transform: translate(-50%, -50%);
          transition: all 0.3s ease-in-out;
        }
      }
      &.slick-active,
      &:hover {
        button {
          &:before {
            background-color: white;
            opacity: 1;
          }
        }
      }
    }
  }
}
