@use '@carbon/colors';
@use '@carbon/react/scss/themes';
@use '@carbon/react/scss/theme' with (
  $fallback: themes.$g90,
  $theme: (
    mag-grey-1: #24272a,
    mag-grey-2: #858788,
    mag-grey-3: #afb0b1,
    mag-grey-4: #d8d9d9,
    mag-grey-5: #f4f4f4,
    mag-pms-425: #55585a,
    mag-blue-1: #0f4875,
    mag-blue-2: #336d94,
    mag-blue-3: #6691af,
    mag-blue-4: #99b6ca,
    mag-blue-5: #ccdbe6,
    mag-brown-1: #7c6d65,
    mag-brown-2: #b7aca4,
    mag-brown-3: #ede5d6,
    mag-green-1: #6a7d39,
    mag-green-2: #9eb07e,
    mag-red: #9a3d37,
    mag-yellow: #dbaa35,
    mag-denim: #004876,
    mag-cadet: #80a3ba,
    mag-indigo: #1e3264,
    mag-aegean: #196c88,
    mag-jade: #33909a,
    mag-moss: #68a08c,
    mag-viridian: #a7baa4,
    mag-pear: #dbd89c,
    mag-peach: #e2ba8a,
    mag-terra: #c88274,
    mag-mesa: #af5d6f,
    mag-plum: #8e476b,
    mag-eggplant: #563d68,
    mag-glacier: #add3d7,
    mag-lily: #bba2bc,
    mag-lilac: #bbb1c3,
    mag-alert: #a03d3d
  )
);
@forward '@carbon/react/scss/theme';
.dark-theme {
  @include theme.theme(
    themes.$g90,
    (
      link-primary: colors.$gray-10
    )
  );
}
.light-theme {
  @include theme.theme(
    themes.$g10,
    (
      link-primary: colors.$gray-100
    )
  );
}
