@use '../../../../node_modules/@carbon/react/scss/breakpoint';
@use '../../../../node_modules/@carbon/react/scss/colors';

// Colors
$gray-10: colors.$gray-10;
$gray-20: colors.$gray-20;
$gray-70: colors.$gray-70;
$gray-80: colors.$gray-80;
$gray-100: colors.$gray-100;
$error-red: colors.$red-50;
$blue-10: colors.$blue-10;
$blue-40: colors.$blue-40;
$blue-40-hover: colors.$blue-40-hover;
$blue-60: colors.$blue-60;
$blue-70: colors.$blue-70;
$blue-80: colors.$blue-80;
@mixin breakpoint-between-font-size($min, $max, $fontSize) {
  @include breakpoint.breakpoint-between($min, $max) {
    font-size: $fontSize;
  }
}
@mixin breakpoint-between-width($min, $max, $width) {
  @include breakpoint.breakpoint-between($min, $max) {
    width: $width;
  }
}
@mixin breakpoint-between-many($min, $max) {
  @include breakpoint.breakpoint-between($min, $max) {
    @content;
  }
}
@mixin breakpoint-down-many($name) {
  @include breakpoint.breakpoint-down($name) {
    @content;
  }
}

.questionContainer {
  display: flex;
  flex-direction: column;
}
.hr {
  width: 100%;
  margin-top: 26px;
  margin-bottom: 15px;
}
.tileGroupRadio {
  height: 100%;
  max-height: inherit;
  :global .cds--tile--is-selected .cds--tile__checkmark {
    opacity: 0;
  }
}
.tileGroupMuti {
  height: 100%;
  max-height: inherit;
}
.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 5px 5px;
  min-height: 3rem;
  border: none;
  outline: none !important;
}
.optionText {
  display: flex;
  gap: 10px;
  align-items: center;
}
.optionText svg {
  opacity: 0;
}
.multiSelectOption {
  display: flex;
  align-items: center;
  :global .cds--tile__checkmark--persistent {
    margin-top: 0.4rem;
  }
}
.selectedOption {
  background-color: var(--peerpoll-selection);
}
.selectedOption svg {
  opacity: 1;
  color: var(--peerpoll-checkmark);
}
.buttonSpacing {
  margin-top: 2.5rem;
  max-width: 8rem;
}
.buttonText {
  display: flex;
  align-items: center;
  gap: 2rem;
}

//-------OVERRIDE OF FLIPPY STYLING----------//

.container {
  :global .flippy-front,
  :global .flippy-back {
    box-shadow: none !important;
  }
}
