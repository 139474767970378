@use '../../../../node_modules/@carbon/react/scss/breakpoint';
@use '../../../../node_modules/@carbon/react/scss/colors';

// Colors
$gray-10: colors.$gray-10;
$gray-20: colors.$gray-20;
$gray-70: colors.$gray-70;
$gray-80: colors.$gray-80;
$gray-100: colors.$gray-100;
$error-red: colors.$red-50;
$blue-10: colors.$blue-10;
$blue-40: colors.$blue-40;
$blue-40-hover: colors.$blue-40-hover;
$blue-60: colors.$blue-60;
$blue-70: colors.$blue-70;
$blue-80: colors.$blue-80;
@mixin breakpoint-between-font-size($min, $max, $fontSize) {
  @include breakpoint.breakpoint-between($min, $max) {
    font-size: $fontSize;
  }
}
@mixin breakpoint-between-width($min, $max, $width) {
  @include breakpoint.breakpoint-between($min, $max) {
    width: $width;
  }
}
@mixin breakpoint-between-many($min, $max) {
  @include breakpoint.breakpoint-between($min, $max) {
    @content;
  }
}
@mixin breakpoint-down-many($name) {
  @include breakpoint.breakpoint-down($name) {
    @content;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: inherit;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  overflow-y: auto;
}

.link {
  align-items: center;
  cursor: pointer;
  color: $blue-60;
}

.link:hover {
  text-decoration: none;
}

.link p {
  font-size: 0.75rem;
  padding-left: 0.5rem;
}

.header {
  // TODO: Need to add/use breakpoints instead of vw or vh
  font-weight: 600;
  padding: 0.5rem;
}
.upperContent {
  display: flex;
  align-items: center;
}
.subheader {
  padding-left: 1rem;
}
.titleWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  white-space: nowrap;
}
.separateWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: inherit;
}
