@use '../../../node_modules/@carbon/react/scss/breakpoint';
@use '../../../node_modules/@carbon/react/scss/colors';

// Colors
$gray-10: colors.$gray-10;
$gray-20: colors.$gray-20;
$gray-70: colors.$gray-70;
$gray-80: colors.$gray-80;
$gray-100: colors.$gray-100;
$error-red: colors.$red-50;
$blue-10: colors.$blue-10;
$blue-40: colors.$blue-40;
$blue-40-hover: colors.$blue-40-hover;
$blue-60: colors.$blue-60;
$blue-70: colors.$blue-70;
$blue-80: colors.$blue-80;
@mixin breakpoint-between-font-size($min, $max, $fontSize) {
  @include breakpoint.breakpoint-between($min, $max) {
    font-size: $fontSize;
  }
}
@mixin breakpoint-between-width($min, $max, $width) {
  @include breakpoint.breakpoint-between($min, $max) {
    width: $width;
  }
}
@mixin breakpoint-between-many($min, $max) {
  @include breakpoint.breakpoint-between($min, $max) {
    @content;
  }
}
@mixin breakpoint-down-many($name) {
  @include breakpoint.breakpoint-down($name) {
    @content;
  }
}

.profileDropdownItem {
  display: flex;
  align-items: center;
  gap: 10px;
  width: inherit;
  height: inherit;
  max-width: 130px;
}

img {
  border-radius: 50%;
  padding: 2px;
  width: 50px;
}
