@use '../../../../node_modules/@carbon/react/scss/breakpoint';
@use '../../../../node_modules/@carbon/react/scss/colors';

// Colors
$gray-10: colors.$gray-10;
$gray-20: colors.$gray-20;
$gray-70: colors.$gray-70;
$gray-80: colors.$gray-80;
$gray-100: colors.$gray-100;
$error-red: colors.$red-50;
$blue-10: colors.$blue-10;
$blue-40: colors.$blue-40;
$blue-40-hover: colors.$blue-40-hover;
$blue-60: colors.$blue-60;
$blue-70: colors.$blue-70;
$blue-80: colors.$blue-80;
@mixin breakpoint-between-font-size($min, $max, $fontSize) {
  @include breakpoint.breakpoint-between($min, $max) {
    font-size: $fontSize;
  }
}
@mixin breakpoint-between-width($min, $max, $width) {
  @include breakpoint.breakpoint-between($min, $max) {
    width: $width;
  }
}
@mixin breakpoint-between-many($min, $max) {
  @include breakpoint.breakpoint-between($min, $max) {
    @content;
  }
}
@mixin breakpoint-down-many($name) {
  @include breakpoint.breakpoint-down($name) {
    @content;
  }
}

.wc {
  display: grid;
  flex-direction: column;
}
.container {
  margin: 0.2rem;
}

.loading {
  display: flex;
  justify-content: center;
}

.topContent {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
}
.topContent svg {
  @include breakpoint-down-many('md') {
    max-width: 1rem;
    max-height: 1rem;
  }
}

.topTitle {
  display: flex;
  align-items: baseline;
}
.titleSize {
  font-weight: 600;
  font-size: 1rem;
  @include breakpoint-down-many('md') {
    font-size: 0.7rem;
  }
}
.subTitle {
  padding-left: 0.5rem;
  font-size: 0.75rem;
  @include breakpoint-down-many('md') {
    font-size: 0.45rem;
  }
}

.middleAlignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.middleAlignment h2 {
  @include breakpoint-between-font-size('xsm', 'md', 1.3rem);
}

.subTextWidth {
  width: 60%;
}
.subTextWidth p {
  @include breakpoint-between-font-size('xsm', 'md', 0.5rem);
}
